import * as React from "react"
import { Link } from "gatsby"
import {
} from "@chakra-ui/react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TermsOfService from '../components/TermsOfService'

function TermsOfServicePage() {
  return (
    <Layout>
      <Seo title="Terms of service" />
      <TermsOfService />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default TermsOfServicePage
